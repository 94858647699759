import React from 'react';
import { PanelProps } from '@grafana/data';
import styled from '@emotion/styled';
import { CircularPanelOptions } from '../types';

interface Props extends PanelProps<CircularPanelOptions> {}

const Circle = styled.div<{ diameter: number }>`
  background: white;
  border-radius: 50%;
  border: 3px solid #005796;
  width: ${({ diameter }) => diameter}px;
  height: ${({ diameter }) => diameter}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${({ diameter }) => diameter * 0.25}px;
  color: #000;
  text-align: center;
  box-sizing: border-box;
`;

const Label = styled.div`
  margin-top: 10px;
  font-size: 1.2em;
  color: black;
  text-align: center;
`;

const Unit = styled.div`
  color: #005796;
  font-size: 0.8em;
  margin-top: 5px;
`;

const Value = styled.div`
  font-size: inherit;
  color: black;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 20px;
`;

const CircularPanel: React.FC<Props> = ({ data, width, height, options }) => {
  const { valueType, units } = options;
  const unitsMap = units?.units || {};
  const diameter = Math.min(width / (data.series.length + 1), height * 0.8);

  const processValue = (values: number[]) => {
    const roundedValue = (val: number) => parseFloat(val.toFixed(2));

    switch (valueType) {
      case 'average':
        return roundedValue(values.reduce((a, b) => a + b, 0) / values.length);
      case 'max-min':
        return roundedValue(Math.max(...values) - Math.min(...values));
      case 'last':
      default:
        return roundedValue(values[values.length - 1]);
    }
  };

  return (
    <Container>
      {data.series.map((series) =>
        series.fields
          .filter((field) => field.type === 'number')
          .map((field) => {
            const values = field.values.toArray();
            const value = processValue(values);
            const parameter = field.name || `Field`;  
            const unit = unitsMap[field.name || parameter] || 'µg/l';
            return (
              <div key={field.name || parameter}>
                <Circle diameter={diameter}>
                  <Value>{value}</Value>
                  <Unit>{unit}</Unit>
                </Circle>
                <Label>{parameter}</Label>
              </div>
            );
          })
      )}
    </Container>
  );
};


export default CircularPanel;