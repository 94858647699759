import React from 'react';
import { useState, useEffect } from 'react';
import { SelectableValue, PanelOptionsEditorProps, DataFrame } from '@grafana/data';
import { Select, InlineField, InlineFieldRow } from '@grafana/ui';
import { CircularPanelOptions } from '../types';
import { UnitType } from '../types';


const unitOptions: Array<SelectableValue<string>> = [
  { label: 'µg/l', value: 'µg/l' },
  { label: 'mg/l', value: 'mg/l' },
  { label: 'Abs/m', value: 'Abs/m' },
  { label: 'NTUeq', value: 'NTUeq' },
  { label: 'HUeq', value: 'HUeq' },
  { label: 'FTU', value: 'FTU' },
  { label: 'uS/cm', value: 'uS/cm' },
  { label: 'mV', value: 'mV' },
  { label: '°C', value: '°C' }
];

const ConfigEditor: React.FC<PanelOptionsEditorProps<CircularPanelOptions>> = ({ value, onChange, context }) => {
  const [units, setUnits] = useState<{ [field: string]: UnitType }>(value.units?.units || {});

  useEffect(() => {
    setUnits(value.units?.units || {});
  }, [value.units]);

  const handleUnitChange = (field: string, selected: SelectableValue<UnitType> | null) => {
    if (selected?.value) {
      const newUnits = { ...units, [field]: selected.value };
      setUnits(newUnits);
      onChange({ ...value, units: { ...value.units.units, units: newUnits } });
    }
  };

  const fields: string[] = [];
  if (context?.data) {
    context.data.forEach((frame: DataFrame) => {
      frame.fields
        .filter((field) => field.type === 'number')
        .forEach((field) => {
          fields.push(field.name);
        });
    });
  }
  

  return (
    <div>
      {fields.length > 0 ? (
        fields.map((field) => (
          <InlineFieldRow key={field}>
            <InlineField label={field} grow>
              <Select
                options={unitOptions}
                value={unitOptions.find(option => option.value === units[field as any]) || unitOptions[0]}
                onChange={(selected) => handleUnitChange(field, selected as any)}
                width={20}
              />
            </InlineField>
          </InlineFieldRow>
        ))
      ) : (
        <div>No fields available</div>
      )}
    </div>
  );
};

export default ConfigEditor;
